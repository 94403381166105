var m = require("mithril")

var DatePicker = require('mithril-datepicker')
var EvGService = require("../services/EvGService")
var VersionCheck = require("../services/VersionCheck")

var AppState = require("../services/AppState")
var Auth = require("../services/Auth")
var Breadcrumb = require("../components/Breadcrumb")

var Settings = require("../models/Settings")
var ChildNote = require("../models/ChildNote");
var DaytypeNote = require("../models/DaytypeNote");
var ActivityResource = require("../models/ActivityResource");
var Schoollogistic = require("../models/Schoollogistic");
var LastMinuteOffer = require("../models/LastMinuteOffer");
var LastMinuteClaim = require("../models/LastMinuteClaim");
var CaretakerChangeRequest = require("../models/CaretakerChangeRequest");

var MenuSelectCount = require("../models/MenuSelectCount");


class Menu {

    constructor(vnode) {
        console.debug("Menu.contructor");
        this._change_chosen_date = this._change_chosen_date.bind(this);
        // this.menu_option_sort = this.menu_option_sort.bind(this);

        // onderstaande heeft geen zin als niet ingelogd
        EvGService.load_server_data();
        EvGService.load_date_related_data();
        this.new_version_available =  VersionCheck.is_new_version_available();

        Settings.setSettingsSubList([]);

        try {
            Breadcrumb.setCrumbs([{url:'/menu', text:'Menu'}]); // in menu doen we dus een soort van reset.
        } catch (e) {
            console.error("Menu Error: "+e);
        }
    }

    _change_chosen_date(chosenDate) {
        // mithrill(-datepicker) 2.x.y(0.9.3) doen het niet goed samen.... hier
        if(chosenDate.toString()=="Invalid Date") {
            alert("Fout bij Zetten Datum (Invalid Date).\n Datum wordt niet aangepast.");
            return
        }
        // bovenstaande moet eruit als mithil-DATEPICKER HET WEER DOET
        AppState.set_filter_date(chosenDate);
        AppState.setChildFilter(0);
        AppState.setSchoolFilter(0);
        // AppState.setDaytypeFilter(0);
        EvGService.load_date_related_data();
    }

    // Als een Menu keuze gemaakt wordt
    menuItemSelelected(event) {
       // houden we dat bij voor 'intelligent gedrag'
       // MenuSelectCount.updateMenuSelect(event.target.id);
       // we pakken de gesaniteerde href ipv id, omdat dat ook al uniek is, en id kan er dan uit
       MenuSelectCount.updateMenuSelect(event.target.href.split('#!/')[1]);
       // MenuSelectCount.updateMenuSelect(event.target.href);
    }


    view(vnode) {
        m.render(document.getElementById("header"),
                 [ m("span.app-version",
                     m('a.silent', {href:"#!/versionupdate"},
                       "Versie: "+VersionCheck.getVersionNumber())),
                   m(Breadcrumb),
                  ]);

        let final_options = [];
        let extra_class = ""

        if(!navigator.onLine) {
            final_options.push(m("a.menu_choice menu_choice_sys_message",
                           "Geen Netwerk/Data verbinding"));

            return m("menu.evg_module",
                     {style:"overflow-y: scroll; height: calc(100vh - 44px);" },
                     final_options)

        }

        // console.log("VersionCheck.is_new_version_available(): "+VersionCheck.is_new_version_available());
        // console.log("Versie Check Vindt Plaats.")
        if( VersionCheck.is_new_version_available()) {
            console.log("Er is een andere versie dan we hier gebruiken, we doen er niets mee.");
        //    options.push(m("a.menu_choice menu_choice_sys_message",
        //                   {href:"#!/versionupdate", onclick:this.menuItemSelelected, id:"menu_version"},
        //                   "Nieuwe Versie Beschikbaar"));
        }
        if(Auth.isLoggedIn()) {
                final_options.push( m(DatePicker,
                                { dialogContainerStyle: "margin: '-50 auto",
                                  date: AppState.get_filter_date_raw(),
                                  onchange: this._change_chosen_date
                                  }
                                )
                            );

                let options = [];
                if(Auth.hasGroup('Crew')){
                   options.push(m("a.menu_choice",
                                {href:"#!/evg-settings", onclick:this.menuItemSelelected},
                                "Instellingen"))
                   options.push(m("a.menu_choice",
                                {href:"#!/evgtoday", onclick:this.menuItemSelelected},
                                "Ons Eiland"));
                   options.push(m("a.menu_choice",
                                {href:"#!/crew-my-schedulelist",  onclick:this.menuItemSelelected},
                                "Mijn Rooster!"));
                   options.push(m("a.menu_choice",
                                {href:"#!/mymentorchildlist", onclick:this.menuItemSelelected},
                                "Mijn MentorKinderen"));

                   // let count_msg = m("span.menu_count_msg", "(voor jou: "+ActivityResource.your_activity_count+")")
                   let count_msg = " (voor jou: "+ActivityResource.your_activity_count+")";
                   options.push(m("a.menu_choice "+(ActivityResource.your_activity_count>0 ? "menu_choice_action_required" : ""),
                                  {href:"#!/activityresourcelist", onclick:this.menuItemSelelected},
                                  ["Gekaderde Activiteiten",
                                   count_msg]))

                   // count_msg = m("span.menu_count_msg", "(voor jou: "+Schoollogistic.your_schoollogistic_count+")")
                   count_msg = " (voor jou: "+Schoollogistic.your_schoollogistic_count+")";
                   options.push(m("a.menu_choice "+(Schoollogistic.your_schoollogistic_count>0 ? "menu_choice_action_required" : ""),
                                  {href:"#!/schoollogistic", onclick:this.menuItemSelelected},
                                  ["Schoollogistiek",
                                   count_msg]))

                   options.push(m("a.menu_choice",
                                  {href:"#!/crewschedulelist", onclick:this.menuItemSelelected},
                                  "Crewlega's"));

                   options.push(m("a.menu_choice",
                                  {href:"#!/childschedulelist", onclick:this.menuItemSelelected},
                                  "Groep Rooster"));

                   options.push(m("a.menu_choice",
                                  {href:"#!/childschoollist", onclick:this.menuItemSelelected},
                                  "School/Kind"));

                   // count_msg = m("span.menu_count_msg", "(ongelezen: "+ChildNote.marked_unseen_count+")")
                   count_msg = " (ongelezen: "+DaytypeNote.marked_unseen_count+")";
                   options.push(m("a.menu_choice "+(ChildNote.marked_unseen_count>0 ? "menu_choice_action_required" : ""),
                                  {href:"#!/childnotelist", onclick:this.menuItemSelelected},
                                  ["Notities/Kind",
                                   count_msg]));

                   // count_msg = m("span.menu_count_msg", "(ongelezen: "+DaytypeNote.marked_unseen_count+")")
                   count_msg = " (ongelezen: "+DaytypeNote.marked_unseen_count+")";
                   options.push(m("a.menu_choice "+(DaytypeNote.marked_unseen_count>0 ? "menu_choice_action_required" : ""),
                                  {href:"#!/daytypenotelist", onclick:this.menuItemSelelected},
                                   ["Notities/Dagsoort",
                                    count_msg]));

                }
                if(Auth.hasGroup('Ouders')){
                   // opties voor ouder
                   options.push(m("a.menu_choice", {href:"#!/mychild"}, "Mijn Kinderen"));
                   let count_msg = [];
                   // Nippertjes
                   if (LastMinuteOffer.count>0) {
                      count_msg.push(m("span.menu_count_msg", "(Beschikbaar: "+LastMinuteOffer.count+")"));
                   }
                   if (LastMinuteClaim.count>0) {
                      count_msg.push(m("span.menu_count_msg", "(Openstaand: "+LastMinuteClaim.count+")"));
                   }
                   options.push(m("a.menu_choice "+(count_msg.length>0 ? "menu_choice_action_required" : ""),
                                  {href:"#!/mylastminute"},
                                   ["Op't nippertje reserveren",
                                    m("span.menu_count_msg", count_msg) ]));
                   // AanpassingsVerzoeken
                   count_msg = m("span.menu_count_msg", "(aantal: "+CaretakerChangeRequest.count+")")
                   options.push(m("a.menu_choice ",
                                  {href:"#!/mychangerequest"},
                                  ["Rooster Aanpassingsverzoeken",
                                   count_msg]));
                }

                if(Settings.getValue('menu_order')==1) {
                    final_options = final_options.concat(options.sort(MenuSelectCount.menuOptionSort));
                } else {
                    // fixed
                    final_options = final_options.concat(options);
                }

                final_options.push(m("a.menu_choice",
                               {href:"#!/logout"},
                               "Uitloggen"))



        } else {
            final_options.push(m("a.menu_choice",
                           {href:"#!/login"},
                           "Inloggen"));
        }

        return m("menu.evg_module",
                 {style:"overflow-y: scroll; height: calc(100vh - 44px);" },
                 final_options)
    }
}

module.exports = Menu
