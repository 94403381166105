var m = require("mithril")
// var Auth = require("../services/Auth")
// var AppState = require("../services/AppState")


class MenuSelectCount {

    constructor() {
       // this._selectCount = localStorage.getItem('menuSelectCount');
       try {
           this._selectCount = JSON.parse(localStorage.getItem('menuSelectCount'));
           if(this._selectCount==null) {
            this._selectCount = {};
           }
       } catch (e) {
            this._selectCount = {};
       }

       this.updateMenuSelect = this.updateMenuSelect.bind(this);
       this.getMenuSelectCount = this.getMenuSelectCount.bind(this);
       this.menuOptionSort = this.menuOptionSort.bind(this);
    }

    updateMenuSelect(menuId) {
       try {
            if(this._selectCount[menuId]==null){
               this._selectCount[menuId] = 1; // eerste keer
            } else {
               this._selectCount[menuId]++;
            };
       } catch (e) {
            console.error("Fout updaten telling ("+menuId+"): "+e);
       }
       localStorage.setItem('menuSelectCount', JSON.stringify(this._selectCount));
    }


    getMenuSelectCount() {
       return this._selectCount
    }

    menuOptionSort(a, b) {
      if(this._selectCount[a.attrs?.href.substr(3)] == undefined ) {
           return 1
      }
      if(this._selectCount[b.attrs?.href.substr(3)] == undefined ) {
           return -1
      }
      // console.log("? "+this._selectCount[b.attrs?.href.substr(3)]+" - "+this._selectCount[a.attrs?.href.substr(3)] +" = "+this._selectCount[b.attrs?.href.substr(3)]-this._selectCount[a.attrs?.href.substr(3)]);
      return this._selectCount[b.attrs?.href.substr(3)] - this._selectCount[a.attrs?.href.substr(3)]
    }

}

module.exports = new MenuSelectCount()
