var m = require("mithril")
var Auth = require("../services/Auth")
var AppState = require("../services/AppState")


class DayType {

    constructor() {
        this.list =  [];
        this.count = 0;
        this._receiveDaytypeList = this._receiveDaytypeList.bind(this);
        this.loadDaytypeList = this.loadDaytypeList.bind(this);
        //this. = this..bind(this);
    }

    _receiveDaytypeList(result) {
        this.count = result.count;
        this.list = result.results;
    }

    loadDaytypeList() {
        const request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/daytype/",
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveDaytypeList)
                .catch((e) => {
                        console.log("loadDaytypeList Error: "+e);
                       })
    }

}

module.exports = new DayType()
